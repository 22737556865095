import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";
import "./style.css";

let position = "";
let screenLayer = "";
let snapMod = "";
// let Data = {};
let id = "";
let content = "";
let commonHeading = "";
let commonDescription = "";
let Api = "";
let MobileImage = "";
const Data = (props) => {
  Api = props.data;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const location = useLocation();
  const slug = location.pathname;

  let ApiEndPoint = Api + slug;
  let ac = "";
  const [scroll, setScroll] = useState(false);
  const [Items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = () => {
    setIsLoading(true);
    fetch(ApiEndPoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        commonHeading = data[0].acf.common_heading;
        commonDescription = data[0].acf.common_description;
        
        ac = data[0].acf.common_content;

        content = ac.map((item, index) => {
          if (index % 2 == 0) {
            position = "page-content-right";
            screenLayer = "screen-layer-right";
          } else {
            position = "page-content-left";
            screenLayer = "screen-layer-left";
          }
          if (item.mobile_image.length > 0) {
            MobileImage = (
              <img
                src={item.mobile_image}
                className="img-fluid w-100 show-responsive"
                alt="studios-banner"
              />
            );
          } else {
            MobileImage = (
              <img
                src={item.image}
                className="img-fluid w-100 show-responsive"
                alt="A London skyscraper"
              />
            );
          }
          return (
            <>
              <section className="snapItem" key={item.name}>
                <div className="screen-layout h-100 position-relative page-image">
                  <div className={" position-relative  " + screenLayer}>
                    <div className="layer layer1"></div>
                    <div className="layer layer2"></div>
                    <div className="layer layer3"></div>
                    <div className="layer layer4"></div>
                    <div className="layer layer5"></div>

                    <img
                      width="100%"
                      src={item.image}
                      className="img-fluid w-100 show-desktop"
                      alt="A London skyscraper"
                    />
                    {MobileImage}
                  </div>
                  <div className="container">
                    <div className={"px-lg-5 py-4 " + position}>
                      <motion.p
                        initial={{ opacity: 0, y: 150 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, type: "easeInOut" }}
                      >
                        {item.description}
                      </motion.p>
                    </div>
                  </div>
                </div>
              </section>
            </>
          );
        });
      });
      setIsLoading(false);
  };

  useEffect(() => {
    fetchData();

    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 150;

      if (scrollCheck !== scroll) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  return (
    <>
      <div className="Wrapper">{isLoading ? <Loader /> : content}</div>
    </>
  );
};

export default Data;
