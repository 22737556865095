import React from "react";
import { CareerComp } from "../Career/Career";
import { SeoItem } from "../Seo/SeoItem";
export const Career = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />

      <div>
        <CareerComp />
      </div>
    </>
  );
};
