import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
// let Data = {};
let Api = "";
let id = "";
let content = "";
let itemNumber = "";
let Ab = "";
let DesktopReperter = "";
let MobileReperter = "";
const Data = (props) => {
  Api = props.data;

  const location = useLocation();
  const slug = location.pathname;
  console.log(slug);
  if (slug === "/services") {
    id = "86";
  } else if (slug === "/about") {
    id = "39";
  }
  let FinalApi = Api + slug;
  let ac = "";
  const [Items, setItems] = useState([]);

  const fetchData = () => {
    fetch(FinalApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        ac = data;

        content = ac.map((item, i) => {
          Ab = item.acf.about_company;

          DesktopReperter = Ab.map((a, i) => {
            if (id === "86") {
              i++;
              itemNumber = "0" + i;
            } else {
              itemNumber = "";
            }
            return (
              <>
                <div className="services-item position-relative">
                  <div className="services-blocks col-md-5">
                    <div className="">
                      <sup>{itemNumber}</sup>
                      <h4>{a.heading}</h4>
                    </div>
                    <div className="services-content">
                      <p dangerouslySetInnerHTML={{ __html: a.description }} />
                    </div>
                  </div>
                </div>
              </>
            );
          });

          MobileReperter = Ab.map((a, i) => {
            if (id === "86") {
              i++;
              itemNumber = "0" + i;
            } else {
              itemNumber = "";
            }
            return (
              <>
                <motion.div
                  className="services-item position-relative"
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, type: "easeInOut" }}
                >
                  <div className="services-blocks col-md-5">
                    <div className="">
                      <sup>{itemNumber}</sup>
                      <h4
                        data-bs-toggle="modal"
                        data-bs-target={"#whoweare" + i}
                        key={a.heading + i}
                      >
                        {a.heading}
                      </h4>
                    </div>
                    <div className="services-content">
                      <p dangerouslySetInnerHTML={{ __html: a.description }} />
                    </div>
                  </div>
                </motion.div>
                <div
                  className="modal responsive-modal fade"
                  id={"whoweare" + i}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="bg-primary vector left-top-vector"></div>
                  <div className="bg-primary vector right-top-vector"></div>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4
                          className="modal-title text-primary"
                          id="exampleModalLabel"
                        >
                          {a.heading}
                        </h4>
                      </div>
                      <div className="modal-body">
                        <button
                          type="button"
                          className="btn-close text-end"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span>+</span>
                        </button>
                        <p
                          dangerouslySetInnerHTML={{ __html: a.description }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          });
          i++;
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="show-desktop">{DesktopReperter}</div>
      <div className="show-responsive">{MobileReperter}</div>
      {/* {Ab} */}
    </>
  );
};

export default Data;
