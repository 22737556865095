import React from "react";
import { HomeBanner } from "../Banner/HomeBanner";
import { AboutCompany } from "../AboutCompany/AboutCompany";
import Api from "../ApiServices/Api";
import { motion } from "framer-motion";
import { SeoItem } from "../Seo/SeoItem";
export const Services = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 1 }}
      >
        <HomeBanner data={Api.PageApi} />
        <AboutCompany data={Api.PageApi} />
      </motion.div>
    </>
  );
};
