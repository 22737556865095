import React, { Component, useRef } from "react";
import axios from "axios";
import Api from "../ApiServices/Api";
import "./contactstyle.css";



class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yourName: "",
      yourEmail: "",
      message: "",
      successMessage: "",
      lodingItem: "",
      loading: false,
      error: "",
    };
  }

  onFormSubmit = (event) => {
    this.state.lodingItem = "Please wait";
    console.log(this.state.lodingItem);
    event.preventDefault();
    const siteURL = Api.Contact;
    const formData = {
      your_name: this.state.yourName,
      your_email: this.state.yourEmail,
      your_message: this.state.message,
    };

    this.setState({ loading: true }, () => {
      axios
        .post(`${siteURL}`, formData, {
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.setState({
            loading: true,
            lodingItem: "Please Wait ",
            yourName: "",
            yourEmail: "",
            message: "",
            successMessage: "Yay your message was sent",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: err.response.data, loading: false });
        });
    });
  };

  handleOnChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };
  handleMouseEnter = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML =
      '<img src="https://plg.langoorqa.net/wp/wp-content/uploads/2022/09/Normal-pointer.png">';
  };
  handleMouseLeave = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML =
      '<img src="https://plg.langoorqa.net/wp/wp-content/uploads/2022/09/Contact-us.png">';
  };
  handleMouseDown = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML = "";
  };

  render() {
    const { yourName, yourEmail, message, successMessage, lodingItem } =
      this.state;

    return (
      <React.Fragment>
        <form
          onSubmit={this.onFormSubmit}
          style={{ margin: "20px" }}
          encType="multipart/form-data"
          className="messagePointer"
        >
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="yourName"
              value={yourName}
              onChange={this.handleOnChange}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseDown={this.handleMouseDown}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="yourEmail"
              value={yourEmail}
              onChange={this.handleOnChange}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseDown={this.handleMouseLeave}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <textarea
              type="textarea"
              className="form-control"
              placeholder="Message"
              name="message"
              value={message}
              onChange={this.handleOnChange}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseDown={this.handleMouseLeave}
              rows="3"
              required
            >
              Message
            </textarea>
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="primary-button">
              Submit
            </button>
            {/* <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> */}
          </div>
          <span>{lodingItem.length > 0 && successMessage}</span>
        </form>
      </React.Fragment>
    );
  }
}
export default Contact;
