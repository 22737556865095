import React, { Component } from "react";
import axios from "axios";
import Api from "../ApiServices/Api";
class EducationPostLeads extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      yourName: "",
      yourDesignation : "",
      yourMobile : "",
      yourEmail: "",
      message: "",
      successMessage: "",
      lodingItem: "",
      loading: false,
      error: "",
    };
  }

  onFormSubmit = (event) => {
    this.state.lodingItem = "";
    console.log(this.state.lodingItem);
    event.preventDefault();
    const siteURL = Api.EducationLeads;
    const formData = {
      your_name: this.state.yourName,
      your_designation : this.state.yourDesignation,
      your_phone : this.state.yourMobile,
      your_email: this.state.yourEmail,
      message: this.state.message,
    };

    this.setState({ loading: true }, () => {
      axios
        .post(
          `${siteURL}`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: true,
            lodingItem: "Please Wait ",
            yourName: "",
            yourEmail: "",
            yourDesignation : "",
            yourMobile: "",
            message: "",
            successMessage: "Yay your message was sent",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: err.response.data, loading: false });
        });
    });
  };

  handleOnChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    
    const { yourName, yourEmail,yourDesignation,yourMobile, message, successMessage, lodingItem } =
      this.state;
    return (
      <React.Fragment>
        <form
          onSubmit={this.onFormSubmit}
          style={{ margin: "20px" }}
          encType="multipart/form-data"
          
        >
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="yourName"
              value={yourName}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Designation"
              name="yourDesignation"
              value={yourDesignation}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Mobile"
              name="yourMobile"
              value={yourMobile}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="yourEmail"
              value={yourEmail}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <textarea
              type="textarea"
              className="form-control"
              placeholder="Message"
              name="message"
              value={message}
              onChange={this.handleOnChange}
              rows="3"
              required
            >
              Message
            </textarea>
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="primary-button">
              Submit
            </button>
            {/* <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> */}
          </div>
          
          <span>{successMessage}</span>
        </form>
      </React.Fragment>
    );
  }
}
export default EducationPostLeads;
