import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Navbar from "./components/Header/Navbar";
import AnimatedRoutes from "./components/Header/AnimatedRoutes";
import { Header } from "./Header";

import TagManager from "react-gtm-module";
import { Footer } from "./Footer";
// import { SeoItem } from "./components/Seo/SeoItem";
// import ReactGA from "react-ga";
// ReactGA.initialize("UA-46806095-1");

const tagManagerArgs = {
  gtmId: "GTM-PRWZQL3",
};

TagManager.initialize(tagManagerArgs);

function App() {
  // ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <Header />

      <Router>
        {/* <SeoItem /> */}
        <Navbar />
        <AnimatedRoutes />
        <Footer />
      </Router>
    </>
  );
}

export default App;
