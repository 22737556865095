import React from "react";
import LandingBanner from "../Banner/LandingBanner";
import Publishers from "../Brands/Publishers";
import LandingAgency from "../Banner/LandingAgency";
import PlgItems from "../PlgItems/PlgItems";
import { SeoItem } from "../Seo/SeoItem";
import Api from "../ApiServices/Api";

export const LandingPage = () => {
  
  return (
    <>
        <SeoItem />
        <div className="logoCurser">
        <LandingBanner data={Api.LandingPage} />
        <Publishers data={Api.LandingPage}/>
        <LandingAgency data={Api.LandingPage}/>
      </div>

    </>
  );
};
