import React from "react";
import EducationPostLeads from "./Educationpostlead";
import NewsLetter from "../NewsLetter/NewsLetter";
import Address from "../Address/Address";
import { motion } from "framer-motion";
export const EducationLeadForm = () => {
  return (
    <>
      <section class="home-contact position-relative messagePointer">
        <div class="bg-primary vector left-top-vector"></div>
        <div class="container h-100">
          <div class="row d-flex align-self-center h-100">
            <div class="col-md-12 text-center align-self-center">
              <div class="text-center mb-5">
                <motion.h2
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, type: "easeInOut" }}
                >
                  Let's{" "}
                  <span class="text-primary text-shadow mb-lg-4">Connect</span>
                </motion.h2>
              </div>
              <div class="col-md-7 mx-auto connect-tabs">
                <ul
                  class="nav nav-pills mb-lg-5 mb-4 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active messagePointer"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Write to Us
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link messagePointer"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Locate us
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link messagePointer"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Subscribe
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <EducationPostLeads />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <Address />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    tabindex="0"
                  >
                    <NewsLetter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
