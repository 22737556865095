import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { motion } from "framer-motion";

import Api from "../ApiServices/Api";
import { useNavigate } from "react-router-dom";
import backarrow from "./sliderimages/back-arrow-key.png";

let landingImage = "";
let GlobalStatus = "";
let WorksDetailsImage = "";
let worksTextContent = "";
let position = "";
let screenLayer = "";
let MobileImage = "";
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
export const Work = () => {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const { id } = useParams();

  const [Works, WorksData] = useState([]);

  // To retrieve data
  const getData = () => {
    let url = Api.Work + id;
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        WorksData(res);
      });
  };
  useEffect(() => {
    getData();
    // localStorage.removeItem("Image");
  }, []);

  return Works.map((item, i) => {
    
  
    let data = {
      Title: item.acf.title,
      Desc: item.acf.description,
      DesktopBanner: item.acf.banner_image,
      MobileBanner: item.acf.slider_image,
      global_work_status_image: item.acf.global_work_status_image,
    };

    GlobalStatus = item.acf.global_work_status.map((item, i) => {
      return (
        <>
          <motion.div
            className="col-md-4 col-6 text-center my-lg-4 my-2"
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: "easeInOut" }}
          >
            <h6 dangerouslySetInnerHTML={{ __html: item.heading_text }} />

            <p
              className="text-uppercase"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </motion.div>
        </>
      );
    });
    WorksDetailsImage = item.acf.details.map((item, index) => {
      if (index % 2 == 0) {
        position = "page-content-right";
        screenLayer = "screen-layer-right";
      } else {
        position = "page-content-left";
        screenLayer = "screen-layer-left";
      }
      if (item.mobile_image.length > 0) {
        MobileImage = item.mobile_image;
      } else {
        MobileImage = item.desktop_image;
      }
      return (
        <>
          <section className="snapItem" key={item.name}>
            <div className="screen-layout h-100 position-relative page-image">
              <div className={" position-relative  " + screenLayer}>
                <div className="layer layer1"></div>
                <div className="layer layer2"></div>
                <div className="layer layer3"></div>
                <div className="layer layer4"></div>
                <div className="layer layer5"></div>
                <img
                  src={item.desktop_image}
                  className="img-fluid w-100 show-desktop"
                  alt="A London skyscraper"
                />
                <img
                  src={MobileImage}
                  className="img-fluid w-100 show-responsive"
                  alt="A London skyscraper"
                />
              </div>
              <div className="container">
                <div className={"px-lg-5 py-4 " + position}>
                  <motion.div
                    className=""
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      );
    });

    landingImage = {
      backgroundImage: `url(${data.DesktopBanner})`,
    };
    let Visiblty = "";

    if (window.gbBanner === "Ho") {
      Visiblty = {
        display: "block",
      };
    } else {
      Visiblty = {
        display: "none",
      };
    }

    return (
      <>
      
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 1 }}
        >
          <div style={Visiblty}>
            <section className="workLanding" style={landingImage}>
              <div
                className="backpage "
               
              >
                <Link to="/work">
                  <span className="px-lg-5 px-4  d-block">
                    <img src={backarrow} /> BACK
                  </span>
                </Link>
              </div>
              <div
                className={"scroll-down text-center mb-lg-5"}
               
                onClick={executeScroll}
              >
                {
                  <span className="fw-900 scroll-down-text">
                    Enter
                    <br />{" "}
                    <i className="fa fa-angle-double-down text-primary"></i>
                  </span>
                }
              </div>
            </section>
          </div>
          <div ref={myRef}></div>
          <section className="case-detail-stream  position-relative logoCurser">
            <div className="bg-primary vector left-bottom-vector"></div>
            <div className="bg-primary vector right-bottom-vector"></div>
            <div className="container">
              <div className="col-md-12 mx-auto">
                <div className="mb-5 text-center">
                  <h5 dangerouslySetInnerHTML={{ __html: data.Desc }} />
                </div>
                <div className="row">
                  <div className="col-md-6 align-self-center stream-heroes">
                    <div className="row">{GlobalStatus}</div>
                  </div>
                  <motion.div
                    className="col-md-6 py-4"
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                  >
                    <img
                      src={data.global_work_status_image}
                      className="img-fluid"
                      alt="case-images"
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </section>

          <div className="Wrapper">{WorksDetailsImage}</div>
        </motion.div>
      </>
    );
  });
};
