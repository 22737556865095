import React, { Component } from "react";
import axios from "axios";
import Api from "../ApiServices/Api";

class LeadsForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      yourName: "",
      email: "",
      position: "",
      company : "",
      phone : "",
      successMessage: "",
      lodingItem: "",
      loading: false,
      error: "",
    };
  }

  onFormSubmit = (event) => {
    this.state.lodingItem = "";
    console.log(this.state.lodingItem);
    event.preventDefault();
    const siteURL = Api.Leadgenration;
    const formData = {
      yourname: this.state.yourName,
      email: this.state.email,
      company: this.state.company,
      phone: this.state.phone,
      position: this.state.position,
    };
    console.log(formData);
    this.setState({ loading: true }, () => {
      axios
        .post(
          `${siteURL}`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({
            loading: true,
            lodingItem: "",
            yourName: "",
            email: "",
            company: "",
            successMessage: "Yay your message was sent",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: err.response.data, loading: false });
        });
    });
  };

  handleOnChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    
    const { yourName, email, company, phone,position, successMessage, lodingItem } =
      this.state;
    return (
      <React.Fragment>
        <form
          onSubmit={this.onFormSubmit}
          style={{ margin: "20px" }}
          encType="multipart/form-data"
          className="messagePointer"
        >
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="yourName"
              value={yourName}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="position"
              name="position"
              value={position}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your Company"
              name="company"
              value={company}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your Phone Number"
              name="phone"
              value={phone}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              value={email}
              onChange={this.handleOnChange}
              required
            ></input>
          </div>
          
          <div className="text-end mt-4">
            <button type="submit" className="primary-button">
              Submit
            </button>
            {/* <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> */}
          </div>
          <span>{successMessage}</span>
        </form>
      </React.Fragment>
    );
  }
}
export default LeadsForm;
