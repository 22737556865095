import { useEffect, useRef } from "react";
import "./style.css";
const Cursor = () => {
  const dot = useRef(null);
  const cursorVisible = useRef(true);
  const cursorEnlarged = useRef(false);
  const endX = useRef(window.innerWidth / 2);
  const endY = useRef(window.innerHeight / 2);
  const requestRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", mouseOverEvent);
    document.addEventListener("mouseup", mouseOutEvent);
    document.addEventListener("mousemove", mouseMoveEvent);
    document.addEventListener("mouseenter", mouseEnterEvent);
    document.addEventListener("mouseleave", mouseLeaveEvent);

    return () => {
      document.removeEventListener("mousedown", mouseOverEvent);
      document.removeEventListener("mouseup", mouseOutEvent);
      document.removeEventListener("mousemove", mouseMoveEvent);
      document.removeEventListener("mouseenter", mouseEnterEvent);
      document.removeEventListener("mouseleave", mouseLeaveEvent);

      cancelAnimationFrame(requestRef.current);
    };
  }, []);
  const toggleCursorVisibility = () => {
    if (cursorVisible.current) {
      dot.current.style.opacity = 1;
    } else {
      dot.current.style.opacity = 0;
    }
  };
  const toggleCursorSize = () => {
    if (cursorEnlarged.current) {
      dot.current.style.transform = " scale(0.75)";
      dot.current.innerHTML =
        '<img src="https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/Normal-pointer.png">';
    } else {
      dot.current.style.transform = " scale(1)";
      dot.current.innerHTML =
        '<img src="https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/Normal-pointer.png">';
    }
  };
  // const cursorZoomOut = () => {
  //   dot.current.style.transform = " scale(1)";
  // };
  const el = document.querySelector("menu-icon");
  console.log(el);
  const mouseOverEvent = () => {
    dot.current.style.transform = "scale(1.5)";
  };
  const mouseOutEvent = () => {
    dot.current.style.transform = "scale(1)";
  };
  const mouseEnterEvent = () => {
    dot.current.style.transform = "scale(1)";
  };
  const mouseLeaveEvent = () => {
    dot.current.style.transform = "scale(1)";
  };
  const mouseMoveEvent = (e) => {
    cursorVisible.current = true;
    toggleCursorVisibility();
    endX.current = e.pageX-20;
    endY.current = e.pageY-20;
    dot.current.style.top = endY.current + "px";
    dot.current.style.left = endX.current + "px";
  };

  return (
    <>
      <div ref={dot} className="cursor-dot d-none d-lg-block">
        <img src="https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/Normal-pointer.png" />
      </div>
    </>
  );
};
export default Cursor;
export function globalMousePoniterEnter() {
  const elements = document.getElementsByClassName("cursor-dot")[0];
  elements.style.transform = " scale(0.80)";
}
export function globalMousePoniterLeave() {
  const elements = document.getElementsByClassName("cursor-dot")[0];
  elements.style.transform = " scale(1)";
  elements.style.backgrounColor = "rgba(0,0,0,0.5)";
}
