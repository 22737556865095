import React from 'react'
import { Helmet } from 'react-helmet';
export const ErrorPage = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
    <Helmet>
      <title>404 page</title>
    </Helmet>
      <section className="page-error position-relative">
        <div className="bg-primary vector left-bottom-vector"></div>
        <div className="bg-primary vector right-bottom-vector right-center-vector"></div>
        <div className="container h-100">
          <div className="row d-flex align-self-center h-100">
            <div className="col-md-12 text-center align-self-center">
              <h1>404 </h1>
              <h5 className="text-primary ">Page not found</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
