import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LeadsForm from "../Connect/LeadGenration";
import LandingLead from "../Connect/LandingLead";
import logo from "../../assets/images/logo.png";

import { motion } from "framer-motion";


let Data = {};
let id = "";
let FormHeading = "";
let section_content = "";
let contact_form_short_content = "";
let contact_form_content = "";
let DesktopBackgroundImage = "";

let Api = "";
const LandingAgency = (props) => {
  Api = props.data;
  const location = useLocation();

  let slug = location.pathname;
  let highlights = "";

  const [BannerItems, SetBannerItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = () => {
    fetch(Api)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        SetBannerItems(data);
        console.log("checking data", data);
        FormHeading = data[0].acf.contact_form_heading;
        section_content = data[0].acf.section_content;
        contact_form_short_content = data[0].acf.contact_form_short_content;
        contact_form_content = data[0].acf.contact_form_content;

        if (data[0].acf.contact_section_background.url == false) {
          DesktopBackgroundImage = "";
        } else {
          DesktopBackgroundImage = data[0].acf.contact_section_background.url;
        }
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        SetBannerItems(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section
        class="phoniex-banner phoniex-banner-form "
        style={{
          backgroundImage: `url(${DesktopBackgroundImage})`,
        }}
      >
        <div class="container">
          {/* <img src={DesktopBackgroundImage} class="phonix-banner-image  show-desktop" alt="studios-banner" /> */}
          {/* <img src={DesktopBackgroundImage} class="img-fluid w-100 show-responsive" alt="studios-banner" /> */}
          <div className="row d-flex justify-content-center">
          <div className="col-11 col-xl-10">
            <div class="row d-flex justify-content-md-center justify-content-lg-between align-items-start">
              <div class="col-md-10 col-lg-6">
                <motion.div
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                    class="lead-generation-form">

                  <img src={logo} class="img-fluid mb-4" width="140px" />
                  <h6 class="mb-3">
                    <span
                      class="text-primary"
                      dangerouslySetInnerHTML={{ __html: FormHeading }}
                    />
                  </h6>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                    className="text-content"
                  dangerouslySetInnerHTML={{
                    __html: contact_form_short_content,
                  }}
                />
                <motion.div
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: contact_form_content }}
                  />
                </motion.div>
              </div>
              <div class="col-md-9 col-lg-5 d-flex justify-content-end flex-column">
                <LandingLead />
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default LandingAgency;
