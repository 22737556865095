import React, { Component } from "react";
import axios from "axios";
import Api from "../ApiServices/Api";
class CareerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plg_team: "",
      favourite_game: "",
      usp: "",
      reach_you: "",
      successMessage: "",
      lodingItem: "",
      loading: false,
      error: "",
    };
  }

  onFormSubmit = (event) => {
    this.state.lodingItem = "Please wait";
    console.log(this.state.lodingItem);
    event.preventDefault();
    const siteURL = Api.Career;
    const formData = {
      plg_team: this.state.plg_team,
      favourite_game: this.state.favourite_game,
      usp: this.state.usp,
      reach_you: this.state.reach_you,
    };
    console.log(formData);
    this.setState({ loading: true }, () => {
      axios
        .post(`${siteURL}`, formData, {
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.setState({
            loading: true,
            lodingItem: "Please Wait ",
            plg_team: "",
            favourite_game: "",
            usp: "",
            reach_you: "",
            successMessage: "Yay your message was sent",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: err.response.data, loading: false });
        });
    });
  };

  handleOnChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      plg_team,
      favourite_game,
      usp,
      reach_you,
      successMessage,
      lodingItem,
    } = this.state;
    return (
      <React.Fragment>
        <form
          onSubmit={this.onFormSubmit}
          style={{ margin: "20px" }}
          encType="multipart/form-data"
          className="messagePointer"
        >
          <div className="form-group mb-3">
            <textarea
              type="textarea"
              className="form-control"
              placeholder="How would you add value to the PLG team?"
              name="plg_team"
              value={plg_team}
              onChange={this.handleOnChange}
              rows="3"
              required
            >
              How would you add value to the PLG team?
            </textarea>
          </div>
          <div className="form-group mb-3">
            <textarea
              type="textarea"
              className="form-control"
              placeholder="What's your favourite game and why?"
              name="favourite_game"
              value={favourite_game}
              onChange={this.handleOnChange}
              rows="3"
              required
            >
              What's your favourite game and why?
            </textarea>
          </div>
          <div className="form-group mb-3">
            <textarea
              type="textarea"
              className="form-control"
              placeholder="What's your USP?"
              name="usp"
              value={usp}
              onChange={this.handleOnChange}
              rows="3"
              required
            >
              What's your USP?
            </textarea>
          </div>
          <div className="form-group mb-3">
            <textarea
              type="textarea"
              className="form-control"
              placeholder="How can we reach you?"
              name="reach_you"
              value={reach_you}
              onChange={this.handleOnChange}
              rows="3"
              required
            >
              How can we reach you?
            </textarea>
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="primary-button">
              Submit
            </button>
            {/* <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div> */}
          </div>
          <span>{lodingItem.length > 0 && successMessage}</span>
        </form>
      </React.Fragment>
    );
  }
}
export default CareerForm;
