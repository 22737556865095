import React from "react";




class Updates extends React.Component {
    state = {
        numChildren: 0
    }

    render () {
        window.scrollTo(0, 0);
        const children = [];

        for (var i = 0; i < this.state.numChildren; i += 1) {
            children.push(<div>Test</div>);
        };

        return (
            <ParentComponent addChild={this.onAddChild}>
                {children}
            </ParentComponent>
        );
    }

    onAddChild = () => {
        this.setState({
            numChildren: this.state.numChildren + 1
        });
    }
}

const ParentComponent = props => (
    <div className="container mt-5" style={{paddingTop: "200px", color:"black"}}>
    <div className="card calculator">
        <p><a href="#" onClick={props.addChild}>Add Another Child Component</a></p>
        <div id="children-pane">
            {props.children}
        </div>
    </div>
    </div>
);



export default Updates