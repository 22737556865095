import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Line2 from "../../assets/images/lines/layer2.png";

let content = "";
let Heading = "";
let logo_listing = "";
let sectionBanner = "";
let delay = "300";
let Api = "";
const Publishers = (props) => {
  Api = props.data;
  const [Users, setItems] = useState([]);

  const fetchData = () => {
    fetch(Api)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        Heading = data[0].acf.logo_listing_heading;
        logo_listing = data[0].acf.logo_listing;

        if (data[0].acf.logo_section_background.url == false) {
          sectionBanner = "";
        } else {
          sectionBanner = data[0].acf.logo_section_background.url;
        }
        content = logo_listing.map((item, index) => {
          // let inittime = 100 + index * 30
          const images = item.logo.url;

          return (
            <>
              <motion.div
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "easeInOut" }}
                key={index++}
                className="col-4 col-md-2 col-lg-2 col-xl-1 align-self-center mb-4 px-4 "
              >
                <img src={images} className="img-fluid" alt="adidas" />
              </motion.div>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section
        className="landing-brand lead-generation-banner position-relative"
        style={{
          backgroundImage: `url(${sectionBanner})`,
        }}
      >
        <div className="container-fluid brand-bg-padding">
          <div class="text-center mb-5">
            <p
              class="text-primary Avenir text-uppercase fw-900"
              dangerouslySetInnerHTML={{ __html: Heading }}
            />
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 px-lg-5">
              <div class="row p-0">{content}</div>
            </div>
          </div>
        </div>
      </section>
      <section className="landing-lines">
        <img src={Line2} className="img-fluid" />
      </section>
    </>
  );
};

export default Publishers;
