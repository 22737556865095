import React, { useState, useEffect, useRef } from "react";


import { Loader } from "../Loader/Loader";
import { useLocation } from "react-router-dom";

let slug = "";

let Api = "";
let AcfLoop = "";

export default function PrivacyPolicy(props) {
  Api = props.data;
  const location = useLocation();

  slug = location.pathname;

  const [ContentItems, setContentItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let FinalApi = Api + slug;
  const handleFetch = () => {
    setIsLoading(true);
    fetch(FinalApi)
      .then((respose) => respose.json())
      .then((respose) => {
        setContentItems(respose);
        setIsLoading(false);

        // Optional code to simulate delay
        setTimeout(() => {
          setContentItems(respose);
          setIsLoading(false);
        }, 2000);
      })
      .catch(() => {
        setErrorMessage("Unable to fetch user list");
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleFetch();
  }, []);

  let RenderContent = ContentItems.map((item, index) => {
    return (
      <>
        <section className="privacy ">
          <div className="container h-100">
            <div className="row d-flex align-self-center h-100">
              <div className="col-md-6  align-self-center">
                <div dangerouslySetInnerHTML={{ __html: item.acf.heading }} />
                <div dangerouslySetInnerHTML={{ __html: item.acf.date }} />
              </div>
              <div className="col-md-6 align-self-center">
                <div className=" text-vertical-scroll cute_scroll">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.acf.description }}
                  />
                  <div className="force-overflow"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  });

  return (
    <>
      {isLoading ? <Loader /> : RenderContent}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </>
  );
}
