import React from "react";

// import Works from "../Works/Works";
import Api from "../ApiServices/Api";
import WorkSlider from "../Works/WorksSlider";
import MobileWorks from "../Works/MobileWorks";
import { SeoItem } from "../Seo/SeoItem";
export const CaseStudy = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <WorkSlider data={Api.WorksApi} />
      <MobileWorks data={Api.WorksApi} />
    </>
  );
};

export default CaseStudy;
