import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./Slider.css";
import { Navigation } from "swiper";
import { motion } from 'framer-motion'

let currentItem = "";
let SliderItem = "";
let Api = "";
let workMobile = "";
window.gbBanner = "Ho";
const WorkSlider = (props) => {
  const location = useLocation();
  const slug = location.pathname;
  //  localStorage.clear();
  const boxRef = useRef(null);
  const cardOverlay = useRef(null);

  const navigate = useNavigate();

  Api = props.data;

  const [Items, setItems] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const fetchData = () => {
    fetch(Api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        SliderItem = data.map((item, index) => {
          let link = item.slug;
          let workId = "work" + index;

          let image = {
            background: `url(${item.acf.banner_image})`,
          };

          let SendingImage = item.acf.banner_image;
          // console.log(SendingImage)
            let delay=0;
            let y=0;
            let opacity=1;
            if(index<4){
                delay=(index/10)*1.5;
                y=-300;
                opacity=0;
            }

          return (
            <>
              <SwiperSlide key={"DesktopItems" + index++}>
                <motion.div
                    initial={{ opacity: opacity, y: y }}
                    whileInView={{ opacity: 1,  y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: delay, duration: 0.5 }}

                  onClick={(event) => handleClick(event, link, SendingImage)}
                  className="boxerr item work-item"
                  style={image}
                >
                  <div className="card-overlay" ref={cardOverlay}>
                    <div className="card-overlay-inner text-center logoCurser">
                      <h4
                        className="text-white"
                        dangerouslySetInnerHTML={{
                          __html: item.title.rendered,
                        }}
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.acf.hover_text,
                        }}
                      />

                      <div className="theam-button" >View more</div>
                    </div>
                  </div>
                </motion.div>
              </SwiperSlide>
            </>
          );
        });

      });
  };

  function handleClick(event, link, image) {
    window.gbBanner = image;

    var hideCardItem = cardOverlay.current;
    hideCardItem.style.display = "none";

    let url = "";
    if (slug === "/" || slug === "home") {
      url = `work/${link}`;
    } else {
      url = `${link}`;
    }

    var position = event.currentTarget.getBoundingClientRect();
    console.log("position is")
    console.log(position);

    currentItem = event.currentTarget.cloneNode(true);
    console.log(currentItem);
    currentItem.style.top = position.y + "px";
    currentItem.style.left = position.x + "px";
    currentItem.style.width = position.width + "px";
    currentItem.style.height = position.height + "px";
    currentItem.style.zIndex = "100000";



      console.log(slug);
      if (slug === "/work") {

      } else {
          document.getElementsByClassName('main-banner')[0].style.zIndex=0;
      }

    currentItem.classList.add("cardoverlHide");
    event.currentTarget.classList.add("cardoverlHide");
    currentItem.classList.add("fixer");
    let d=350;
      setTimeout(() => {
          boxRef.current.appendChild(currentItem);
      }, d);

      setTimeout(() => {
        currentItem.classList.add("expand");
          currentItem.style.width =  "100%";
          currentItem.style.height = "100vh";
        currentItem.style.top = 0 + "px";
        currentItem.style.left = 0 + "px";
        currentItem.style.zIndex = "100000";

      }, d+100);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, d+1000);
      setTimeout(() => {
        navigate(url);
      }, d+2000);

  }

  useEffect(() => {
    fetchData();
  }, [boxRef, cardOverlay]);

  return (
    <>
      <section className="our-works"  >
        <div className="text-center mb-5">
          <h2>
            Our <span className="text-primary text-shadow">work</span>
          </h2>
          <p className="text-uppercase">case studies</p>
        </div>
        <div className="show-desktop">
          <Swiper
            ref={boxRef}
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              500: {
                slidesPerView: 0,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1200: {
                slidesPerView: 3.3,
                spaceBetween: 0,
              },
              1440: {
                slidesPerView: 3.8,
                spaceBetween: 0,
              },
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper logoCurser parent"
          >
            {SliderItem}
          </Swiper>
        </div>

      </section>
    </>
  );
};

export default WorkSlider;

// window.gbBanner = GbBanner;
