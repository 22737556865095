import React from "react";


import Brands from "../Brands/Brands";
import { Studio } from "../Studios/Studio";
import { Connect } from "../Connect/connect";
import Video from "../VideoPlayer/Video";
import WorkSlider from "../Works/WorksSlider";
import MobileWorks from "../Works/MobileWorks";
import "../../App.css";
import Api from "../ApiServices/Api";
import { HomeBanner } from "../Banner/HomeBanner";
import { StaticBanner } from "../Banner/StaticBanner";
import { SeoItem } from "../Seo/SeoItem";

export const Home = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);

  return (
    <>
      <SeoItem />
      <StaticBanner />
      {/* <HomeBanner data={Api.PageApi} /> */}

      <Video data={Api.PageApi} />
      <div className="logoCurser">
        <Brands />
        <WorkSlider data={Api.WorksApi} />
        <MobileWorks data={Api.WorksApi} />
        <Studio data={Api.PageApi} />
      </div>
      <Connect />
    </>
  );
};

export default Home;
