import React from "react";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/style.css";
import { Link } from "react-router-dom";
import twitch from "./assets/images/twitch.png";
import instagram from "./assets/images/instagram.png";
import youtube from "./assets/images/youtube.png";
import discord from "./assets/images/discord.png";
import twitter from "./assets/images/twitter.png";
import facebook from "./assets/images/facebook.png";
import whatsapp from "./assets/images/What's-app.png";
import { globalMousePoniterEnter } from "./components/Curser/Cursor";
import { globalMousePoniterLeave } from "./components/Curser/Cursor";
export const Footer = () => {
  return (
    <>
    <div class="sticky-items">
      <Link
        className="theam-button stickey-button"
        to="/contact-us"
        onMouseEnter={globalMousePoniterEnter}
        onMouseLeave={globalMousePoniterLeave}
      >
        Get in Touch
      </Link>
      <div className="stickey-whatsapp">
        <a href="https://api.whatsapp.com/send?phone=+971 551257087&text=Hi!" target="_blank" rel="noopener noreferrer">
      <img src={whatsapp} className="wp-icon" />
      </a>
      </div>
      </div>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-3 text-lg-start text-center order-3 order-lg-1">
              <p class="text-uppercase">
                {" "}
                © Copyright 2022 Power League Gaming FZ LLC.
              </p>
            </div>
            <div class="col-md-5 text-center order-1 order-lg-2">
              <ul className="p-0 mb-0">
                <li>
                  <Link to={"/career"}>careers</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Privacy policy</Link>
                </li>
                <li>
                  <Link to={"/cookies-policy"}>cookie policy</Link>
                </li>
              </ul>
            </div>
            <div class="col-md-4 text-lg-end text-center order-2 order-lg-3">
              <ul class="social-media p-0 m-0">
                <li>
                  <a href="https://www.twitch.tv/plg" target="_blank">
                    <img src={twitch} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/plgbuzz/" target="_blank">
                    <img src={instagram} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/c/PLGBuzz" target="_blank">
                    <img src={youtube} className="img-fluid" />
                  </a>
                </li>

                <li>
                  <a href="https://twitter.com/plgbuzz" target="_blank">
                    <img src={twitter} className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/powerleaguegaming"
                    target="_blank"
                  >
                    <img src={facebook} className="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
