import React from "react";
import LeadGenrationBanner from "../Banner/LeadGenrationBanner";
import Brands from "../Brands/Brands";
import PlgItems from "../PlgItems/PlgItems";
import { SeoItem } from "../Seo/SeoItem";
import Api from "../ApiServices/Api";
export const LeadGenration = () => {
  return (
    <>
      <SeoItem />
      <div className="logoCurser">
        <LeadGenrationBanner data={Api.PageApi} />
        <Brands />
        
      </div>
    </>
  );
};
