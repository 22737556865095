import React from "react";
import { HomeBanner } from "../Banner/HomeBanner";

import { ContentItem } from "../ContentItems/ContentItem";
import { SeoItem } from "../Seo/SeoItem";
import Api from "../ApiServices/Api";

function Studio() {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <HomeBanner data={Api.PageApi} />
      <ContentItem data={Api.PageApi} />
    </>
  );
}

export default Studio;
