import React, { useEffect, useState } from "react";
import Api from "../ApiServices/Api";
import { motion } from "framer-motion";
let content = "";
let Heading = "";
let Descrition = "";
let delay = "300";
let FinalApi = Api.Studio;
const Brands = () => {
  let ac = "";
  const [Users, setItems] = useState([]);

  const fetchData = () => {
    fetch(FinalApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        Heading = data.acf.heading;
        Descrition = data.acf.description;
        ac = data.acf.our_clients;

        content = ac.map((item, index) => {
          return (
            <>
              <motion.div
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "easeInOut" }}
                key={item.client_logo + index}
                className="col-md-3 col-4 align-self-center mb-4 px-4"
              >
                <img
                  src={item.client_logo}
                  className="img-fluid"
                  alt="adidas"
                />
              </motion.div>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="position-relative plg-brand">
        <div className="bg-primary vector left-bottom-vector"></div>
        <div className="bg-primary vector right-bottom-vector"></div>
        <div className="container h-100">
          <div className="d-flex align-self-center h-100">
            <div className="row col-lg-11 mx-auto">
              <motion.div
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "easeInOut" }}
                className="col-lg-3 col-md-6 mb-5 align-self-center"
              >
                <p
                  className="mb-lg-5 mb-4"
                  dangerouslySetInnerHTML={{ __html: Descrition }}
                />
              </motion.div>
              <div className="col-lg-7 ms-auto align-self-center text-center">
                <div className="row">{content}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brands;
