import React from "react";
import CareerForm from "./CareerForm";
export const CareerComp = () => {
  return (
    <div>
      <section class="career position-relative">
        <div class="bg-primary vector left-bottom-vector"></div>
        <div class="bg-primary vector right-bottom-vector right-center-vector"></div>
        <div class="container h-100">
          <div class="row d-flex align-self-center h-100">
            <div class="col-md-7 mx-auto text-center align-self-center">
              <div class="text-center mb-5 px-lg-5 px-4">
                <h2 class="mb-lg-4">
                  <span class="text-primary text-shadow ">Careers</span>
                </h2>
                <p>
                  If you'd like to join the PLG team, we'd love to hear your
                  responses and thoughts on some of the question below: (Q's
                  with field to be filled)
                </p>
              </div>
              <CareerForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
