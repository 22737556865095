import React from "react";

export const Events = () => {
  document.body.classList.remove("nav-active");
  return (
    <div>
      <h1 className="text-center my-5">Events</h1>
    </div>
  );
};
