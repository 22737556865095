import React from "react";
import { HomeBanner } from "../Banner/HomeBanner";
import AboutUsContent from "../AboutCompany/AboutUsContent";
// import {ContentItem} from "../ContentItems/ContentItem";
import Api from "../ApiServices/Api";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { SeoItem } from "../Seo/SeoItem";
function About() {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 1 }}
      >
        <div className="logoCurser">
          <HomeBanner data={Api.PageApi} />
          <AboutUsContent data={Api.PageApi} />
          {/* <ContentItem data={Api.PageApi} /> */}
        </div>
      </motion.div>
    </>
  );
}

export default About;
