import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './team.css';

let TeamItems = "";
let Api = "";
let Heading = "";
export const TeamSlider = (props) => {
  Api = props.data;
  const location = useLocation();
  const slug = location.pathname;

  const [Items, setItems] = useState([]);
  let ApiEndPoint = Api + slug;
  const fetchData = () => {
    fetch(ApiEndPoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        console.log(data[0].acf);
        Heading = data[0].acf.heading;
        TeamItems = data[0].acf.team_details.map((member, index) => {
          var bgImage = member.avatar;
          index++;

          return (
            <>
              <li>
                <div className="team-wrapper">
                  <div
                    className="team-card"
                    style={{ backgroundImage: `url(${bgImage})` }}
                  >
                    {/* <div class="card-header text-center text-black bg-primary pb-0 border-0 px-2">
                    <h6>{member.team_member_name}</h6>
                    <p class="text-uppercase"> {member.designation}</p>
                  </div> */}
                  </div>
                  <div class="card-footer team-card-footer text-center  pb-0 border-0 px-2">
                    <div className="row d-flex justify-content-center">
                      <div className="col-11">
                        <h6>{member.team_member_name}</h6>
                        <p class="text-uppercase text-primary">
                          {member.designation}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <section class="team-members position-relative ">
        <div class="bg-primary vector left-bottom-vector"></div>
        <div class="bg-primary vector right-bottom-vector"></div>
        <div class="container">
          <div class="col-md-11 text-center mx-auto mb-5">
            <div dangerouslySetInnerHTML={{ __html: Heading }} />
          </div>
        </div>
        <div class="leaders">
          <ul class="p-0">{TeamItems}</ul>
        </div>
      </section>
    </>
  );
};
export default TeamSlider;
