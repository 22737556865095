import React from "react";
import HomeBannerItems from "./HomeBannerItem";

export const HomeBanner = (props) => {
  return (
    <>
      <HomeBannerItems data={props.data} />
      
    </>
  );
};
