import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import "./Slider.css";

let currentItem = "";

let Api = "";
let workMobile = "";
window.gbBanner = "Ho";
const MobileWorks = (props) => {
  const location = useLocation();
  const slug = location.pathname;

  const boxRef = useRef(null);
  const cardOverlay = useRef(null);

  const navigate = useNavigate();

  Api = props.data;

  const [Items, setItems] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const fetchData = () => {
    fetch(Api, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        let AllMobileWorks = "";
        if (slug === "/" || slug === "home") {
          AllMobileWorks = data.slice(0, 3);
        } else {
          AllMobileWorks = data;
        }

        workMobile = AllMobileWorks.map((work, index) => {
          let link = work.slug;
          let workId = "work" + index;

          let image = {
            background: `url(${work.acf.banner_image})`,
          };

          let SendingImage = work.acf.banner_image;
          return (
            <>
              {/* Showing Works on Mobile/tabs Device */}

              <div
                className="boxerrMobile item work-item mb-2 "
                key={"Mobile Itmes" + index++}
                onClick={(event) => handleClick(event, link, SendingImage)}
                style={image}
              >
                <div className="card-overlay">
                  <div className="card-overlay-inner text-center logoCurser">
                    <div className="theam-button">View more</div>
                  </div>
                </div>
              </div>
            </>
          );
        });
      });
  };

  function handleClick(event, link, image) {
    window.gbBanner = image;

    let url = "";
    if (slug === "/" || slug === "home") {
      url = `work/${link}`;
    } else {
      url = `${link}`;
    }

    var position = event.currentTarget.getBoundingClientRect();

    currentItem = event.currentTarget.cloneNode(true);
    console.log(currentItem);
    currentItem.style.top = position.y + "px";
    currentItem.style.left = position.x + "px";
    currentItem.style.zIndex = "100000";
    currentItem.classList.add("cardoverlHide");
    event.currentTarget.classList.add("cardoverlHide");
    currentItem.classList.add("fixer");
    setTimeout(() => {
      let append = boxRef.current.appendChild(currentItem);

      setTimeout(() => {
          currentItem.classList.add("expand");
          currentItem.style.width = "100%";
          currentItem.style.height = "100vh";
          currentItem.style.top = 0 + "px";
          currentItem.style.left = 0 + "px";
          currentItem.style.zIndex = "100000";
      }, 100);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
      setTimeout(() => {
        navigate(url);
      }, 2000);
    }, 1);
  }

  useEffect(() => {
    fetchData();
  }, [boxRef, cardOverlay]);
  let ViewMore = "";
  if (slug === "/" || slug === "home") {
    ViewMore = true;
  } else {
    ViewMore = false;
  }

  return (
    <>
      <div className="show-responsive">
        <div className="container" ref={boxRef}>
          {workMobile}
          {ViewMore && (
            <div className="text-center mt-4 logoCurser">
              <Link to={`/work`} className="primary-button px-4">
                View more
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileWorks;

// window.gbBanner = GbBanner;
