import React, { useState, useEffect, useRef } from "react";

import { Loader } from "../Loader/Loader";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

let slug = "";
let EnterButton = "";
let Api = "";
let backgroundStyle = "";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export default function HomeBannerItems(props) {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  Api = props.data;
  const location = useLocation();

  slug = location.pathname;

  if (slug === "/" || slug === "/home") {
    slug = "home";
    EnterButton = " d-block";
  } else {
    EnterButton = " d-block";
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [BannerItems, setBannerItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let FinalApi = Api + slug;
  const handleFetch = () => {
    setIsLoading(true);
    fetch(FinalApi)
      .then((respose) => respose.json())
      .then((respose) => {
        setBannerItems(respose);
        setIsLoading(false);
        // Optional code to simulate delay
        // setTimeout(() => {
        setBannerItems(respose);
        setIsLoading(false);
        // }, 1);
      })
      .catch(() => {
        setErrorMessage("Something error");
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleFetch();
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //   console.log(BannerItems);

  let renderUser = BannerItems.map((item, index) => {
    let heading = item.acf.heading;
    let DesktopBanner = item.acf.image.url;
    let MobileBanner = item.acf.banner_mobile.url;

    if (windowSize.innerWidth > 500) {
      backgroundStyle = {
        background: `url(${DesktopBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      };
    } else {
      backgroundStyle = {
        background: `url(${MobileBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      };
    }
    return (
      <>
        <motion.section
          className="main-banner home-banner position-relative snapCenter"
          style={backgroundStyle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-primary vector left-bottom-vector"></div>
          <div className="bg-primary vector right-bottom-vector"></div>
          <div className="bg-primary vector right-bottom-short-vector"></div>
          <div className=" h-100">
            <div className="d-flex align-self-center h-100">
              <div className="col-md-12 text-center align-self-center">
                {/* <img
                  className="img-fluid w-100 show-desktop"
                  src={DesktopBanner}
                />
                <img
                  className="img-fluid w-100 show-responsive"
                  src={MobileBanner}
                /> */}
                <div className="banner-text">
                  <motion.div
                    initial={{ opacity: 0, y: 150 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                    dangerouslySetInnerHTML={{ __html: heading }}
                  ></motion.div>
                </div>
              </div>
            </div>
            <div
              className={"scroll-down text-center mb-lg-5" + EnterButton}
              onClick={executeScroll}
            >
              {
                <span className="fw-900 scroll-down-text">
                  Enter
                  <br />{" "}
                  <i className="fa fa-angle-double-down text-primary"></i>
                </span>
              }
            </div>
          </div>
        </motion.section>
        <div ref={myRef}></div>
      </>
    );
  });

  return (
    <>
      {isLoading ? <Loader /> : renderUser}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </>
  );
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
}
