import React, { useState, useEffect, useRef } from "react";
import { Work } from "./Work";
import { useNavigate, Link } from "react-router-dom";
import backarrow from "./sliderimages/back-arrow-key.png";
import "./WorksSlider";
import { motion } from "framer-motion";

console.log(window.gbBanner);
var bgImage = "";
let landingImage = "";
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
export const WorkBanner = () => {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  useEffect(() => {
    bgImage = window.gbBanner;

    window.scrollTo(0, 0);
    // localStorage.clear();
  }, []);

  landingImage = {
    backgroundImage: `url(${window.gbBanner})`,
  };

  let Visiblty = "";

  if (window.gbBanner === "Ho") {
    Visiblty = {
      display: "none",
    };
  } else {
    Visiblty = {
      display: "block",
    };
  }
  console.log(window.gbBanner);

  return (
    <>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 1 }}
      >
        <div className="bg-dark" style={Visiblty}>
          <section className="workLanding" style={landingImage}>
            <div className="backpage ">
              <Link to="/work">
                <span className="px-lg-5 px-4">
                  <motion.img
                    src={backarrow}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  />{" "}
                  BACK
                </span>
              </Link>
            </div>
            <div
              className={"scroll-down text-center mb-lg-5"}
              onClick={executeScroll}
            >
              {
                <span className="fw-900 scroll-down-text">
                  Enter
                  <br />{" "}
                  <i className="fa fa-angle-double-down text-primary"></i>
                </span>
              }
            </div>
          </section>
          <div ref={myRef}></div>
        </div>
        <Work />
      </motion.div>
    </>
  );
};
