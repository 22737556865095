import React from "react";
import { Link } from "react-router-dom";
import Scroll from "../Scroll/Scroll";
import logo from "./logo.png";
import Menu from "./Menu";
import MenuNewsLetter from "../NewsLetter/MenuNewsLetter";
import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <Scroll />

      <header>
        <nav class="navbar main-menu navbar-expand-lg py-lg-4">
          <div class="container-fluid px-0">
            <Link class="navbar-brand px-lg-5 px-4 logoCurser" exact to={"/"}>
              <img src={logo} class="img-fluid"   />
            </Link>
            <Menu />
            <div class="nav">
              <div class="nav__content">
                <div class="container h-100">
                  <div class="hamburger-menu">
                    <div class="row mx-lg-5 mx-0 h-lg-100 ">
                      <div class="col-lg-6 mt-5 align-self-center order-2 order-lg-1">
                        {/* <div class="menu-contact py-lg-4 py-3">
                          <h6 class="text-primary">contact us</h6>
                          <ul class="p-0">
                            <li>
                              {" "}
                              <a href="">
                                <i class="fa fa-envelope-o text-primary me-2"></i>{" "}
                                info@powerleaguegaming.com
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a href="">
                                <i class="fa fa-map-marker text-primary me-2"></i>{" "}
                                B33, Production City, Dubai, United Arab
                                Emirates
                              </a>
                            </li>
                          </ul>
                        </div> */}
                        <div class="menu-contact py-lg-4 py-3">
                          <h6 class="text-primary mb-3">Subscribe</h6>
                          <MenuNewsLetter />
                        </div>
                        <div class="menu-contact py-lg-4 py-3">
                          <h6 class="text-primary mb-3">community</h6>
                          <ul class="social-media p-0 m-0">
                            <li>
                              <a href="https://www.twitch.tv/plg" target="_blank">
                                <i class="fa fa-twitch"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/plgbuzz/" target="_blank">
                                <i class="fa fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.youtube.com/c/PLGBuzz" target="_blank">
                                <i class="fa fa-youtube-play"></i>{" "}
                              </a>
                            </li>
                            <li>
                              <a href="https://twitter.com/plgbuzz" target="_blank">
                                <i class="fa fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/powerleaguegaming" target="_blank">
                                <i class="fa fa-facebook"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-6 bg-primary p-md-5 h-lg-100 align-self-center position-relative order-1 order-lg-2">
                        <ul class="nav__list px-lg-4 px-0 m-0">
                          <li class="nav__list-item">
                            <Link exact to={"/"} >
                              Home
                            </Link>
                          </li>
                          <li class="nav__list-item">
                            <Link to={"/about-us"} >About Us</Link>
                          </li>
                          <li class="nav__list-item">
                            <Link to={"/services"} >Services</Link>
                          </li>
                          <li class="nav__list-item" >
                            <Link to={"/education"} >Education</Link>
                          </li>
                          <li class="nav__list-item">
                            <Link to={"/work"} >case study</Link>
                          </li>
                          <li class="nav__list-item">
                            <Link to={"/studio"} >plg studios </Link>
                          </li>
                           <li class="nav__list-item">
                            <Link to={"/team"} >team</Link>
                          </li>
                          <li class="nav__list-item">
                            <Link to={"/career"} >career</Link>
                          </li>
                          <li class="nav__list-item">
                            <Link to={"/contact-us"} >Contact us</Link>
                          </li>
                        </ul>
                        <div class="news-list">
                          <ul class="nav__list  p-lg-4  px-0 m-0">

                            <Link to={"/media-center"} >
                              <div class="primary-button text-black p-0 mb-2">
                                Media Center
                              </div>
                            </Link>
                            <li class="nav__list-item">
                              <Link to={"/media-center"}>IP</Link>
                            </li>
                            <li class="nav__list-item">
                              <Link to={"/media-center"}>PR</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* <header>
        <nav class="navbar main-menu navbar-expand-lg py-4">
          <div class="container-fluid px-0">
            <a class="navbar-brand px-lg-5 px-4" href="index.php">
              <img src={logo} class="img-fluid" />
            </a>
            <Menu />
            <div className="nav">
              <div className="nav__content">
                <ul className="nav__list">
                  <Link className="nav__list-item" to={"/"}>
                    Home
                  </Link>
                  <Link className="nav__list-item" to={"/about"}>
                    About
                  </Link>
                  <Link className="nav__list-item" to={"/education"}>
                    Education
                  </Link>
                  <Link className="nav__list-item" to={"/services"}>
                    Services
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header> */}
      {/* <header>
        <div className="ps-5 pt-3">
          <img src={logo} className="img-fluid" />
        </div>
        <Menu />
        <div className="nav">
          <div className="nav__content">
            <ul className="nav__list">
              <Link className="nav__list-item" to={"/"}>
                Home
              </Link>
              <Link className="nav__list-item" to={"/about"}>
                About
              </Link>

              <li className="nav__list-item">Projects</li>
              <li className="nav__list-item">Contact</li>
            </ul>
          </div>
        </div>
      </header> */}
    </>
  );
};

export default Navbar;
