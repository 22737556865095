import React, { Component } from "react";
import PlayerIcon from "./videoassets/video-icon.png";
import "./style.css";

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timestamps: [],
      isPlaying: false,
      playIcon: true,
      items: [],
    };
  }
  componentDidMount() {
    let api = this.props.data + "home";

    fetch(api)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
        });
      });
  }

  videoRef = React.createRef();

  handlePlay = () => {
    console.log(window.innerWidth)
    if (this.state.isPlaying === true) {
      this.videoRef.current.pause();
      this.setState({
        isPlaying: false,
      });

      this.setState({
        playIcon: true,
      });
    } else {
      this.videoRef.current.play();
      this.setState({
        isPlaying: true,
      });
      this.setState({
        playIcon: false,
      });
    }
  };
  PlayWithicon = () => {
    if (this.state.isPlaying === true) {
      this.videoRef.current.pause();
      this.setState({
        isPlaying: false,
      });

      this.setState({
        playIcon: true,
      });
    } else {
      this.videoRef.current.play();
      this.setState({
        isPlaying: true,
      });
      this.setState({
        playIcon: false,
      });
    }
  };
  render() {
    let videoPoster = "";
    let VideoUrl = "";
    // let MobilePoster = "";
    // let MobilePlayer = "";
    const { items } = this.state;
    items.map((item, index) => {
      videoPoster = item.acf.video_poster;
      VideoUrl = item.acf.video_url;
      // MobilePoster = item.acf.video_mobile_poster;
      // MobilePlayer = item.acf.mobile_video_url;
    });
    // console.log(window.innerWidth);
    return (
      <>
        <section className="position-relative video-play">
          <video
            ref={this.videoRef}
            autoPlay={false}
            poster={videoPoster}
            src={VideoUrl}
            onClick={this.handlePlay}
            className="w-100 videoPlayer videoPoniter snapCenter "
          ></video>

          {this.state.playIcon && (
            <img
              onClick={this.PlayWithicon}
              className="play-icon"
              src={PlayerIcon}
            />
          )}
        </section>
      </>
    );
  }
}
