import React, { useEffect, useRef } from "react";
import Contact from "./contact";
import LeadsForm from "./LeadGenration";
import NewsLetter from "../NewsLetter/NewsLetter";
import Address from "../Address/Address";
import { useLocation } from "react-router-dom";

import { mouseOverEvent, mouseOutEvent } from "../Curser/Cursor";
import { ConnetPointer } from "../Curser/MailCursor";

// import "./connectstyle.css";
let Form = "";

export const Connect = () => {
  const MailSection = useRef(true);
  const MailCurser = useRef(false);

  const location = useLocation();
  let slug = location.pathname;
  // let slug2 = "";

  if (slug === "/" || slug === "/home") {
    Form = <Contact />;
  } else if (slug === "/" || slug === "/contact-us") {
    Form = <Contact />;
  } else {
    Form = <LeadsForm />;
  }
  useEffect(() => {
    // document.addEventListener('mousedown', mouseOverEvent);
    // document.addEventListener('mouseup', mouseOverEvent);
    // document.addEventListener('mousemove', mouseOverEvent);
    // document.addEventListener('mouseenter', mouseOverEvent);
    // document.addEventListener('mouseleave', mouseOverEvent);
  }, []);

  function mouseOverEvent() {
    const elements = document.getElementsByClassName("mail-curser");
    elements.style.transform = "translate(-50%, -50%) scale(0.75)";
    elements.innerHTML = "asdf>";
  }
  // console.log(MailSection.current)
  if (MailSection.current != null) {
  } else {
  }
  const handleMouseEnter = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML =
      '<img src="https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/Contact-us.png">';
  };
  const handleMouseLeave = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML =
      '<img src="https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/Normal-pointer.png">';
  };
  return (
    <>
      <div ref={MailCurser} className="Mail-curser"></div>
      <section
        class="home-contact contact-us position-relative messagePointer"
        ref={MailSection}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseEnter}
        // onMouseUp
      >
        <div class="bg-primary vector left-top-vector"></div>
        <div class="bg-primary vector right-top-vector"></div>
        <div class="container h-100">
          <div class="row d-flex align-self-center h-100">
            <div class="col-md-12 text-center align-self-center messagePointer">
              <div class="text-center mb-5">
                <h2 data-aos="fade-up">
                  Let's{" "}
                  <span class="text-primary text-shadow mb-lg-4">Connect</span>
                </h2>
              </div>
              <div class="col-lg-7 col-md-12 mx-auto connect-tabs messagePointer">
                <ul
                  class="nav nav-pills mb-lg-5 mb-4 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active messagePointer"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Write to Us
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link messagePointer"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Locate us
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link messagePointer"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Subscribe
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    {Form}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                   
                    <Address />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    tabindex="0"
                  >
                    <NewsLetter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
