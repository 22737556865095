var Api = {
  PageApi : "https://www.powerleaguegaming.com/wp/wp-json/wp/v2/pages?slug=",
  WorksApi : "https://www.powerleaguegaming.com/wp/wp-json/wp/v2/works?embed",
  Work : "https://www.powerleaguegaming.com/wp/wp-json/wp/v2/works?slug=",
  Studio : "https://www.powerleaguegaming.com/wp/wp-json/acf/v3/options/our-brands",
  Contact : "https://www.powerleaguegaming.com/wp/wp-json/contact-form-7/v1/contact-forms/37/feedback",
  Leadgenration : "https://www.powerleaguegaming.com/wp/wp-json/contact-form-7/v1/contact-forms/276/feedback",
  EducationLeads : "https://www.powerleaguegaming.com/wp/wp-json/contact-form-7/v1/contact-forms/289/feedback",
  Career : "https://www.powerleaguegaming.com/wp/wp-json/contact-form-7/v1/contact-forms/416/feedback",
  NewsLetter : "https://www.powerleaguegaming.com/wp/wp-json/contact-form-7/v1/contact-forms/418/feedback",
  Address : "https://www.powerleaguegaming.com/wp/wp-json/acf/v3/options/address",
  LandingPage: "https://www.powerleaguegaming.com/wp/wp-json/wp/v2/pages?slug=why-plg"
}

export default Api;