import React from "react";
import CookiesPolicyCom from "../PrivacyPolicy/CookiesPolicy";
import Api from "../ApiServices/Api";
import { SeoItem } from "../Seo/SeoItem";
export const CookiesPolicy = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <CookiesPolicyCom data={Api.PageApi} />
    </>
  );
};
