import React, { useEffect, useState } from "react";
import Api from "../ApiServices/Api";

let address = "";
let emails = "";
let map = "";
let emailGrp = "";

let FinalApi = Api.Address;
const Address = () => {
  const [Items, setItems] = useState([]);

  const fetchData = () => {
    fetch(FinalApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        address = data.acf.address;
        emails = data.acf.email_ids;
        map = data.acf.map;
        emailGrp = emails.map((email, index) => {
          return (
            <>

              <div className="text-center mb-0" key={index++}>
                <div dangerouslySetInnerHTML={{ __html: email.email_id }} />
              </div>


            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: address }} />

      {emailGrp}
      <div dangerouslySetInnerHTML={{ __html: map }} />


    </>
  );
};

export default Address;
