import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

let MainHeading = "";
let MainDescription = "";
let Brands = "";
let Api = "";
export const MediaCenterBrands = (props) => {
  Api = props.data;
  const location = useLocation();
  const slug = location.pathname;
  const [Items, setItems] = useState([]);
  let ApiEndPoint = Api + slug;
  const fetchData = () => {
    fetch(ApiEndPoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        MainHeading = data[0].acf.brand_heading;
        MainDescription = data[0].acf.brand_description;

        console.log(data[0].acf.brand_heading);

        Brands = data[0].acf.brand_items.map((brand, index) => {
          index++;

          return (
            <>
              <motion.div
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "easeInOut" }}
                className="col-md-4 col-6 align-self-center mb-1 pe-1  px-0"
                key={index}
              >
                <div className="brand-item work-item">
                  <img
                    src={brand.brand_image}
                    className="img-fluid"
                    alt="miss-image"
                  />
                  <div className="card-overlay">
                    <div className="card-overlay-inner text-center">
                      <p>{brand.brand_small_description}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <section className="position-relative plg-brand">
        <div className="bg-primary vector left-bottom-vector"></div>
        <div className="bg-primary vector right-bottom-vector"></div>
        <div className="container h-100">
          <div className="d-flex align-self-center h-100">
            <div className="row col-md-12 mx-auto">
              <div className="col-md-4 mb-lg-5 mb-4 align-self-center">
                <h4 className="mb-4">
                  <motion.div
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, type: "easeInOut" }}
                    dangerouslySetInnerHTML={{ __html: MainHeading }}
                  />
                </h4>
                <motion.div
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, type: "easeInOut" }}
                  dangerouslySetInnerHTML={{ __html: MainDescription }}
                />
              </div>
              <div className="col-md-7 ms-auto align-self-center text-center">
                <div className="row">{Brands}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MediaCenterBrands;
