import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LeadsForm from '../Connect/LeadGenration';

let Data = {};
let id = "";
let BannerHeading = "";
let Description = "";
let DesktopBackgroundImage = "";
let MobileBackground = "";
let FormHeading = "";

let Api = "";
const LeadGenrationBanner = (props) => {
  Api = props.data;
  const location = useLocation();

  let slug = location.pathname;

  const [BannerItems, SetBannerItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = () => {
    fetch(Api + slug)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        SetBannerItems(data);

        BannerHeading = data[0].acf.heading;
        Description = data[0].acf.description;
        FormHeading = data[0].acf.form_heading;

        if (data[0].acf.background_image == false) {
          DesktopBackgroundImage = "";
        } else {
          DesktopBackgroundImage = data[0].acf.background_image;
        }
        if (data[0].acf.mobile_background_image == false) {
          MobileBackground = "";
        } else {
          MobileBackground = data[0].acf.mobile_background_image;
        }
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        SetBannerItems(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // let Heading = Users.acf.heading
  // console.log(Heading)
  return (
    <>
      <section class="main-banner lead-generation-banner  page-banner  position-relative pb-5">
        <div class="bg-primary vector left-bottom-vector"></div>
        <div class="bg-primary vector right-bottom-vector"></div>
        <div class="bg-primary vector right-bottom-short-vector"></div>
        <div class="container-fluid px-0 h-100">
          <img
            src={DesktopBackgroundImage}
            class="img-fluid w-100 show-desktop"
          />
          <img
            src={MobileBackground}
            class="img-fluid w-100 show-responsive"
            alt="studios-banner"
          />
          <div class="banner-text row col-md-10 mx-auto d-flex align-self-center h-100">
            <div class="col-md-5 mx-auto py-3 align-self-center px-lg-5">
              <div class="education-bannertext px-4">
                <div dangerouslySetInnerHTML={{ __html: BannerHeading }} />
                <div dangerouslySetInnerHTML={{ __html: Description }} />
              </div>
            </div>
            <div class="col-md-5 py-3 mx-auto align-self-center lead-generation-form px-lg-5 px-4">
              <div dangerouslySetInnerHTML={{ __html: FormHeading }} />

              <LeadsForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeadGenrationBanner;
