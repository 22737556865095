import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../ApiServices/Api";

import { Helmet, HelmetProvider } from "react-helmet-async";
let slug = "";
let url = "";
let title = "";
let desc = "";
let Pageurl = window.location.href;
export const SeoItem = () => {
  const [items, setItems] = useState([]);
  const location = useLocation();

  slug = location.pathname;

  if (slug === "/" || slug === "/home") {
    url = Api.PageApi + "home";
  } else {
    url = Api.PageApi + slug;
  }
  async function fetchData() {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setItems(data);

    title = data[0].yoast_head_json.title;
    desc = data[0].yoast_head_json.description;
    console.log(Pageurl)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <link rel='canonical' href={Pageurl} />
          <meta name="description" content={desc} />
        </Helmet>
      </HelmetProvider>
    </>
  );
};
