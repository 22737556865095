import React from "react";
import { Connect } from "../Connect/connect";
import { SeoItem } from "../Seo/SeoItem";

export const ContactUs = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <Connect />
    </>
  );
};
