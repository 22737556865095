import React from "react";
import { HomeBanner } from "../Banner/HomeBanner";
import { MediaCenterBrands } from "../Brands/MediaCenterBrands";
import PR from "../ContentItems/PR";
import Api from "../ApiServices/Api";
import { SeoItem } from "../Seo/SeoItem";
export const MediaCenter = () => {
  document.body.classList.remove("nav-active");
  return (
    <>
      <SeoItem />
      <HomeBanner data={Api.PageApi} />
      <MediaCenterBrands data={Api.PageApi} />
      <PR data={Api.PageApi} />
    </>
  );
};
