import React, { Component } from "react";
import axios from "axios";
import Api from "../ApiServices/Api";
class MenuNewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news_letter: "",
      successMessage: "",
      lodingItem: "",
      loading: false,
      error: "",
    };
  }

  onFormSubmit = (event) => {
    this.state.lodingItem = "Please wait";
    console.log(this.state.lodingItem);
    event.preventDefault();
    const siteURL = Api.NewsLetter;
    const formData = {
      news_letter: this.state.news_letter,
    };

    this.setState({ loading: true }, () => {
      axios
        .post(`${siteURL}`, formData, {
          method : 'POST',
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.setState({
            loading: true,
            lodingItem: "Please Wait ",
            news_letter: "",
            successMessage: "Yay your Email was sent",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: err.response.data, loading: false });
        });
    });
  };

  handleOnChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { news_letter, successMessage, lodingItem } = this.state;
    return (
      <React.Fragment>
        <form
         onSubmit={this.onFormSubmit}
         encType="multipart/form-data"
         className="messagePointer"
        >
          <div className="form-group d-lg-flex mb-3">
            <input
              type="email"
              className="form-control w-50 bg-primary "
              placeholder="Email"
              name="news_letter"
              value={news_letter}
              onChange={this.handleOnChange}
              required
            />
            <button
              type="submit"
              className="primary-button col-md-4 text-start ps-4 text-primary"
            >
              Submit
            </button>
          </div>
          <span>{lodingItem.length > 0 && successMessage}</span>
        </form>
        
      </React.Fragment>
    );
  }
}
export default MenuNewsLetter;
