import React from "react";
import LoaderIcon from "./loading.gif";
import { motion, AnimatePresence } from "framer-motion"

import "./style.css";

export const Loader = () => {
  return (
    <>
    <AnimatePresence
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
      <div className="d-flex align-items-center justify-content-center loader-bg ">


              <img
                  src={LoaderIcon}
                  width="50px"

              />

      </div>
    </AnimatePresence>
    </>
  );
};
