import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

let PRItems = "";
let Api = "";
let Heading = "";
export const PR = (props) => {
  Api = props.data;
  const location = useLocation();
  const slug = location.pathname;

  const [Items, setItems] = useState([]);

  let ApiEndPoint = Api + slug;
  const fetchData = () => {
    fetch(ApiEndPoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        // console.log(data[0].acf);
        Heading = data[0].acf.pr_heading;
        PRItems = data[0].acf.pr_items.map((brand, index) => {
          index++;

          return (
            <>
              <motion.div
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "easeInOut" }}
                class="col-md-4 mb-4"
                key={index}
              >
                <div class="pr-item text-center m-lg-4 mb-3">
                  <a href={brand.pr_url} target="_blank">
                    <div className="pr-content work-item">
                      <img src={brand.pr_image} class="img-fluid mb-3" />
                      <div class="card-overlay">
                        <div class="card-overlay-inner text-center">
                          <p>{brand.pr_description}</p>
                        </div>
                      </div>
                    </div>
                    <h6 class="text-primary">{brand.pr_heading}</h6>
                  </a>
                </div>
              </motion.div>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <section class="pr-media position-relative">
        <div class="bg-primary vector right-top-short-vector"></div>
        <div class="bg-primary vector left-top-vector right-center-vector"></div>
        <div class="container">
          <motion.div
            class="text-center mb-5"
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: "easeInOut" }}
          >
            <div dangerouslySetInnerHTML={{ __html: Heading }} />
          </motion.div>
          <motion.div
            class="row col-md-11 mx-auto"
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: "easeInOut" }}
          >
            {PRItems}
          </motion.div>
        </div>
      </section>
    </>
  );
};
export default PR;
