import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Service from "../Services/Service";
import { Education } from "../Pages/Education";
import { Services } from "../Pages/Services";
import CaseStudy from "../Pages/CaseStudy";
import { PrivacyPolicy } from "../Pages/Privacy-Policy";
import { Work } from "../Works/Work";
import { WorkBanner } from "../Works/WorkBanner";
import Studio from "../Pages/Studio";
import { MediaCenter } from "../Pages/MediaCenter";
import { LeadGenration } from "../Pages/LeadGenration";
import { Team } from "../Pages/Team";
import { CookiesPolicy } from "../Pages/CookiesPolicy";
import { Career } from "../Pages/Career";
import { ContactUs } from "../Pages/ContactUs";
import { News } from "../Pages/News";
import { Events } from "../Pages/Events";
import Updates from "../Pages/Updates";
import { ErrorPage } from "../Pages/ErrorPage";
import { motion, AnimatePresence } from "framer-motion";
import { LandingPage } from "../Pages/Landing-page";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/education" element={<Education />} />
        <Route path="/services" element={<Services />} />
        <Route path="/work" element={<CaseStudy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/service/:id" element={<Service />} /> */}
        {/* <Route path="/work/:id" element={<Work />} /> */}
        <Route path="/work/:id" element={<WorkBanner />} />
        <Route path="/work/:id" element={<Work />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/media-center" element={<MediaCenter />} />
        <Route path="/lead-generation" element={<LeadGenration />} />
        <Route path="/team" element={<Team />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/news" element={<News />} />
        <Route path="/events" element={<Events />} />
        <Route path="/why-plg" element={<LandingPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
