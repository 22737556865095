import React from "react";
import Data from "./Data";
export const AboutCompany = (props) => {
  
  return (
    <>
      
      <section class="services position-relative" >
        <div class="bg-primary vector right-top-short-vector"></div>
        <div class="bg-primary vector left-top-vector"></div>
        <div class="container">
        <Data data={props.data}/>
        </div>
      </section>
    </>
  );
};
