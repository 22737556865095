
import React, { useState } from "react";


export default function Menu() {

  const handleClick = (event) => {
    if(document.body.classList.contains('nav-active')){
        document.body.classList.remove("nav-active");
    }
    else{
        document.body.classList.add("nav-active");
    }

  };
  return (
    <div class="menu-icon px-lg-5 px-4 py-4" onClick={handleClick} >
      <span class="menu-icon__line menu-icon__line-left"></span>
      <span class="menu-icon__line"></span>
      <span class="menu-icon__line menu-icon__line-right"></span>
    </div>
  );
}
