import React from "react";
import PrivacyPolicyComponent from "../PrivacyPolicy/PrivacyPolicy";

import Api from "../ApiServices/Api";
import { SeoItem } from "../Seo/SeoItem";
export const PrivacyPolicy = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <PrivacyPolicyComponent data={Api.PageApi} />
    </>
  );
};
