import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./Slider.css";

import { Navigation } from "swiper";
let slug = "";
let Api = "";
let DisplayItems = "";
let DisplayItemsForMobile = "";

export const Studio = (props) => {
  const location = useLocation();

  slug = location.pathname;

  Api = props.data;

  if (slug === "/" || slug === "/home") {
    slug = "home";
  }
  let FinalApi = Api + slug;
  let gettingItems = "";
  const [Items, setItems] = useState([]);

  const fetchData = () => {
    fetch(FinalApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        gettingItems = data[0].acf.studio_slider;

        DisplayItems = gettingItems.map((item, index) => {
          return (
            <>
              <SwiperSlide key={"index" + index}>
                <div className="item">
                  <img src={item.desktop_image} alt={item.desktop_image} />
                </div>
              </SwiperSlide>
            </>
          );
        });

        DisplayItemsForMobile = gettingItems.map((item, index) => {
          return (
            <>
              <SwiperSlide key={"index" + index}>
                <div className="item">
                  <img src={item.mobile_image} className="img-fluid " />
                </div>
              </SwiperSlide>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section class="studios h-100 position-relative">
        <div class="bg-primary vector left-bottom-vector"></div>
        <div class="bg-primary vector right-bottom-vector"></div>
        <div class="bg-primary vector left-top-vector"></div>
        <div class="bg-primary vector right-top-short-vector"></div>
        <div class="text-center mb-5">
          <motion.h2
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: "easeInOut" }}
          >
            PLg <span class="text-primary text-shadow">Studios</span>
          </motion.h2>
          <motion.p
            class="text-uppercase"
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: "easeInOut" }}
          >
            Host 360° production studio
          </motion.p>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            500: {
              slidesPerView: 0,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1200: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper show-desktop"
          
        >
          {DisplayItems}
        </Swiper>

        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            500: {
              slidesPerView: 0,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            1200: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper show-responsive"
        >
          {DisplayItemsForMobile}
        </Swiper>
      </section>
    </>
  );
};
