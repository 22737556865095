import React, { Component } from "react";
import axios from "axios";
import Api from "../ApiServices/Api";
class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news_letter: "",
      successMessage: "",
      lodingItem: "",
      loading: false,
      error: "",
    };
  }

  onFormSubmit = (event) => {
    this.state.lodingItem = "Please wait";
    console.log(this.state.lodingItem);
    event.preventDefault();
    const siteURL = Api.NewsLetter;
    const formData = {
      news_letter: this.state.news_letter,
    };

    this.setState({ loading: true }, () => {
      axios
        .post(`${siteURL}`, formData, {
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.setState({
            loading: true,
            lodingItem: "Please Wait ",
            news_letter: "",
            successMessage: "Yay your Email was sent",
          });
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.setState({ error: err.response.data, loading: false });
        });
    });
  };

  handleOnChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

     handleMouseEnter = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML =
        '<img src="https://plg.langoorqa.net/wp/wp-content/uploads/2022/09/Normal-pointer.png">';
};
     handleMouseLeave = () => {
    document.getElementsByClassName("cursor-dot")[0].innerHTML =
        '<img src="https://plg.langoorqa.net/wp/wp-content/uploads/2022/09/Contact-us.png">';
};
  render() {
    const { news_letter, successMessage, lodingItem } = this.state;
    return (
      <React.Fragment>
        <form
          onSubmit={this.onFormSubmit}
          style={{ margin: "20px" }}
          encType="multipart/form-data"
          className="messagePointer"
        >
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="news_letter"
              value={news_letter}
              onChange={this.handleOnChange}
              required
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseDown={this.handleMouseEnter}
            ></input>
          </div>
          <div className="text-center mt-4">
            <button type="submit" className="theam-button px-4">
              Subscribe
            </button>
          </div>
        
          <span>{lodingItem.length > 0 && successMessage}</span>
          {/* <span>{}</span> */}
        </form>
      </React.Fragment>
    );
  }
}
export default NewsLetter;
