import React from "react";
import TeamSlider from "../Team/TeamSlider";
import Api from "../ApiServices/Api";
import { SeoItem } from "../Seo/SeoItem";
export const Team = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <TeamSlider data={Api.PageApi} />
    </>
  );
};

// export default Team;
