import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

// let Data = {};
let id = "";
let content = "";
let commonHeading = "";
let commonDescription = "";
let Api = "";
const CookiesPolicyCom = (props) => {
  Api = props.data;

  const location = useLocation();
  const slug = location.pathname;
  let ApiEndPoint = Api + slug;

  let ac = "";
  const [Items, setItems] = useState([]);

  const fetchData = () => {
    fetch(ApiEndPoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);

        commonHeading = data[0].acf.common_heading;
        commonDescription = data[0].acf.common_description;
        ac = data[0].acf.terms_and_conditions;
      
        content = ac.map((item, index) => {
          return (
            <>
              <div className="col-md-9 py-lg-5 pb-5 align-self-center">
                <div dangerouslySetInnerHTML={{ __html: item.heading }} />
                <div className="mb-4">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.last_update_date }}
                  />
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </>
          );
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="terms-banner  position-relative">
        <div className="bg-primary vector left-bottom-vector"></div>
        <div className="bg-primary vector right-bottom-vector"></div>
        <div className="container h-100">
          <div className="row d-flex align-self-center h-100">{content}</div>
        </div>
      </section>
    </>
  );
};

export default CookiesPolicyCom;
