import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Line1 from "../../assets/images/lines/line1.png";

let Data = {};
let id = "";
let BannerHeading = "";
let content = "";
let section_content = "";
let Description = "";
let DesktopBackgroundImage = "";
let MobileBackground = "";
let FormHeading = "";

let Api = "";
const LandingBanner = (props) => {
  Api = props.data;
  const location = useLocation();

  let slug = location.pathname;
  let highlights = "";

  const [BannerItems, SetBannerItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = () => {
    fetch(Api)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        SetBannerItems(data);
        // console.log('checking data', data)
        BannerHeading = data[0].acf.section_heading;
        highlights =data?.[0]?.acf.highlights
        content = highlights.map((item, index) => {
          const inittime = 100 + index * 30;
          const number = item.number;
          const details = item.details;
          
          return (

            <motion.div
                initial={{ opacity: 0, y: 150 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "easeInOut" }}
                key={index} className="col-md-4 col-6 my-lg-3 my-2">
              <h6><span className="text-primary">{number} </span></h6>
              <p className="text-uppercase">{details}</p>
            </motion.div>
          );
        });
        section_content= data[0].acf.section_content;
        Description = data[0].acf.description;
        FormHeading = data[0].acf.form_heading;

        if (data[0].acf.section_background_image.url == false) {
          DesktopBackgroundImage = "";
        } else {
          DesktopBackgroundImage = data[0].acf.section_background_image.url;
        }
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        SetBannerItems(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // let Heading = Users.acf.heading
  // console.log(Heading)
  return (
    <>
     <section
        class="phoniex-banner phoniex-banner-form phoniex-banner-padding"
        style={{
          backgroundImage: `url(${DesktopBackgroundImage})`,
        }}
      >
        <div class="container-fluid px-0 h-100 ">
          
          {/* <img src={DesktopBackgroundImage} class="phonix-banner-image show-desktop" alt="studios-banner" />
          <img src={DesktopBackgroundImage} class="img-fluid w-100 show-responsive" alt="studios-banner" /> */}
          <div class="banner-text row col-md-11 mx-auto d-flex align-self-center h-100">
          <div class="col-md-10 col-lg-7 col-xl-5 offset-md-0 mt-2 align-self-center">

              <div class="education-bannertext">
                <div class="w-lg-75">
                    <h4 class="mb-3"><span class="text-primary" dangerouslySetInnerHTML={{ __html: BannerHeading }} /></h4>
                </div>
                <div className="row">{content}</div>
                <div dangerouslySetInnerHTML={{ __html: section_content }} />
              </div>
            </div>
            {/* <div class="col-md-5 py-3 mx-auto align-self-center lead-generation-form px-lg-5 px-4">
              <div dangerouslySetInnerHTML={{ __html: FormHeading }} />

              <LeadsForm />
            </div> */}
          </div>
        </div>
      
      </section>
      <section className="landing-lines">
        <img src={Line1} className="img-fluid" />
      </section>
    </>
  );
};

export default LandingBanner;
