import React from "react";
import { HomeBanner } from "../Banner/HomeBanner";
import { BackroundImageBanner } from "../Banner/BackroundImageBanner";
import { ContentItem } from "../ContentItems/ContentItem";
import { EducationLeadForm } from "../EducationLeads/EducationleadForm";
import { SeoItem } from "../Seo/SeoItem";
import Api from "../ApiServices/Api";

export const Education = () => {
  document.body.classList.remove("nav-active");
  window.scrollTo(0, 0);
  return (
    <>
      <SeoItem />
      <HomeBanner data={Api.PageApi} />
      <ContentItem data={Api.PageApi} />
      <EducationLeadForm />
    </>
  );
};
