import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./bannerstyle.css";
let backgroundStyle = "";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const StaticBanner = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  if (windowSize.innerWidth > 500) {
    backgroundStyle = {
      background: `url(https://plg.langoorqa.net/wp/wp-content/uploads/2022/08/home-main-banner.jpg)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top center",
    };
  } else {
    backgroundStyle = {
      background: `url(https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/home-1.jpg)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top center",
    };
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <motion.section
        className="main-banner home-banner position-relative snapCenter"
        style={backgroundStyle}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-primary vector left-bottom-vector"></div>
        <div className="bg-primary vector right-bottom-vector"></div>
        <div className="bg-primary vector right-bottom-short-vector"></div>
        <div className=" h-100">
          <div className="d-flex align-self-center h-100">
            <div className="col-md-12 text-center align-self-center">
              <div className="banner-text">
                <motion.div
                  initial={{ opacity: 0, y: 150 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, type: "easeInOut" }}
                >
                  <h1>
                    We Define How Brands build
                    <br /> Relationships with{" "}
                    <span class="text-primary text-shadow">
                      Gaming audiences
                    </span>
                  </h1>
                </motion.div>
              </div>
            </div>
          </div>
          <div
            className={"scroll-down text-center mb-lg-5"}
            onClick={executeScroll}
          >
            {
              <span className="fw-900 scroll-down-text">
                Enter
                <br /> <i className="fa fa-angle-double-down text-primary"></i>
              </span>
            }
          </div>
        </div>
      </motion.section>
      <div ref={myRef}></div>
    </>
  );

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
};
