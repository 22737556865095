import React from "react";
import "./style.css";

import { motion, useScroll } from "framer-motion";

export default function Scroll() {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <motion.div
        className="top-progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
    </>
  );
}
