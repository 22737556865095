import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

let Api = "";
let Content = "";
const AboutUsContent = (props) => {
  Api = props.data;

  const location = useLocation();
  const slug = location.pathname;

  let FinalApi = Api + slug;
  let ac = "";
  const [Items, setItems] = useState([]);

  const fetchData = () => {
    fetch(FinalApi)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
        Content = data[0].content.rendered;
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section className="about-section">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div dangerouslySetInnerHTML={{ __html: Content }} />

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsContent;
